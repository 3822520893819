.footer-copyright{
    color: get-color(light,1);
    a{
        color: get-color(primary,1);
    }
    a:hover{
        color: get-color(primary,2);
    }
}

.list-reset{
    a{
        color: get-color(light,1)
    }
    a:hover{
        color: get-color(light,2)
    }
}