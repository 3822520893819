.feature-bg {
    background-color: get-color(dark, 1);
    color: get-color(light,1);
}

.feature-bg h2{
    color: get-color(light,1);
}

.feature-bg span{
    color: get-color(light,1);
}
